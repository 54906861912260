<template>
  <div>
    <div :class="type == 'pc' ? 'pcView' : ''">
      <div :class="type == 'phone' ? 'phoneBackground' : ''">
        <div :class="type == 'phone' ? 'phoneView' : ''">
          <div :class="type == 'phone' ? 'one' : ''"></div>
          <div :class="type == 'phone' ? 'two' : ''"></div>
          <div :class="type == 'phone' ? 'three' : ''">
            <iframe
              :class="type == 'pc' ? 'pcView' : ''"
              style="width: 100%; height: 100%"
              :src="src"
              frameborder="0"
            ></iframe>
          </div>
          <div :class="type == 'phone' ? 'four' : ''"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import pcView from "./pc.vue";
// import phoneView from "./phone.vue";
export default {
  components: {
    // pcView,
    // phoneView,
  },
  props: {
    type: "", // pc|phone
    src: "", // iframe的url
  },
};
</script>

<style lang="less" scoped>
.pcView {
  width: 100%;
  height: 100%;
  position: absolute;
}
.phoneBackground {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;

  background: #444a53;
  display: flex;
  justify-content: center;
  align-items: center;
}
.phoneView {
  width: 407px;
  height: 810px;
  background: #fff;
  border-radius: 40px;

  position: relative;
  transform: scale(0.8);

  .one {
    width: 11px;
    height: 11px;
    border-radius: 11px;
    background: #e3e3e3;
    position: absolute;
    top: 26px;
    left: 198px;
  }
  .two {
    width: 87px;
    height: 8px;
    border-radius: 8px;
    background: #e3e3e3;
    position: absolute;
    top: 50px;
    left: 160px;
  }
  .three {
    width: 375px;
    height: 660px;
    background: #e3e3e3;
    position: absolute;
    top: 76px;
    left: 16px;
  }
  .four {
    width: 43px;
    height: 43px;
    border-radius: 43px;
    background: #e3e3e3;
    position: absolute;
    bottom: 20px;
    left: 182px;
  }
}
</style>