<template>
  <div class="editWebPage" v-loading="loadingGet">
    <div class="my-nav">
      <el-row style="">
        <el-col :span="12">
          <el-page-header @back="goBack">
            <div slot="content">
              <!-- <span>编辑网站</span>&nbsp;&nbsp;&nbsp; -->
              <el-radio v-model="webViewType" :label="'pc'">
                <i class="el-icon-monitor"></i>
                {{ $lang("editWeb_pc") }}
              </el-radio>
              <el-radio v-model="webViewType" :label="'phone'">
                <i class="el-icon-mobile-phone"></i>
                {{ $lang("editWeb_phone") }}
              </el-radio>
            </div>
          </el-page-header>
        </el-col>

        <el-col :span="12" style="text-align: right">
          <!-- <span style="color: #409eff">
            网站地址:
            <a
              target="blank"
              style="color: #409eff"
              :href="'http://' + web.host_in"
              >{{ web.host_in }}</a
            >
          </span> -->

          <!-- &nbsp;
            <el-button size="mini" @click="utils_copy(web.host_in)"
              >复制</el-button
            >
            <el-button size="mini" @click="goOpen('http://' + web.host_in)"
              >访问</el-button
            > -->

          <el-button
            size="mini"
            type="primary"
            :loading="loadingSave"
            @click="doSave"
          >
            {{ $lang("editWeb_preservation") }}
          </el-button>
          <el-button size="mini" @click="doSee">
            {{ $lang("editWeb_preview") }}
          </el-button>
        </el-col>
      </el-row>
    </div>

    <div class="pagesDiv" v-if="this.web.pages.length != 1">
      &nbsp;
      <el-radio
        v-for="item in this.web.pages"
        v-model="page_id"
        :label="item.id"
        :key="item.id"
        >{{ item.template_page_name }}</el-radio
      >
    </div>

    <div
      class="editDiv"
      v-if="!loadingGet"
      :class="this.web.pages.length == 1 ? 'editDivTop60' : ''"
    >
      <displayView :type="webViewType" :src="webf_src" />
    </div>
  </div>
</template>

<script>
import displayView from "@/components/editWeb/displayView/main.vue";

import { get_web_info, update_web_info } from "@/api/web.js";
import { config } from "@/config/index.js";
import md5 from "md5";

import copy from "copy-to-clipboard";

export default {
  components: {
    displayView,
  },
  data() {
    return {
      loadingGet: false,
      loadingSave: false,
      webViewType: "pc",

      web_id: 0,
      page_id: 0,
      web: {
        pages: [],
      },
      web_host: config.web_host,
    };
  },
  computed: {
    webf_src() {
      return (
        config.web_host + config.page_edit_url + "?page_id=" + this.page_id
      );
    },
    language() {
      return this.$store.getters.language;
    },
  },
  mounted() {
    // 获取网站实例id
    let web_id = this.$route.query.web_id;
    this.web_id = web_id;
    this.get_web_info();

    window.setKeys = this.setKeys;
  },

  methods: {
    setKeys(page_name, page_keys) {
      console.log("page_name", page_name);
      console.log("page_keys", page_keys);
      for (let i = 0; i < this.web.pages.length; i++) {
        const page = this.web.pages[i];
        if (page.template_page_name == page_name) {
          this.web.pages[i].keys = page_keys;
        }
      }
    },
    goBack() {
      this.$router.push("/index/userCenter/web/list");
    },
    goOpen(p) {
      p ? window.open(p) : "";
    },

    get_web_info() {
      // 开始 loadingGet
      this.loadingGet = true;
      get_web_info({ web_id: this.web_id })
        .then((res) => {
          console.log("-get_web_info.res-", res);
          if (res.code == 200) {
            this.web = res.data.web;
            if (this.web.pages.length) {
              this.page_id = this.web.pages[0].id;
            }
          }
        })
        .catch((err) => {
          // console.log("-err-", err);
        })
        .finally(() => {
          // 结束 loadingGet
          this.loadingGet = false;
        });
    },

    doSave() {
      this.onSubmit();
    },
    doSee() {
      this.goOpen("http://" + this.web.host_in);
    },
    onSubmit() {
      this.loadingSave = true;

      update_web_info({ web_p: this.web })
        .then((res) => {
          if (res.code == 200) {
            console.log("-update_web_info.res-", res);
            // this.get_web_info();
            this.$notify({
              type: "success",
              message: "保存成功",
            });
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loadingSave = false;
        });
    },

    utils_copy(p) {
      try {
        copy(p);
        this.$notify({
          type: "success",
          message: "复制成功",
        });
      } catch (error) {
        this.$notify({
          type: "error",
          message: "复制失败:" + error.message,
        });
      }
    },
  },
};
</script>


<style lang="less">
.editWebPage {
  min-width: 1300px;
  height: 100vh;
  position: relative;
  .my-nav {
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-bottom: 1px solid #eee;
    height: 60px;
    font-size: 14px;
  }
  .pagesDiv {
    padding: 20px;
    border-bottom: 1px solid #eee;
    line-height: 20px;
  }

  .editDiv {
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
  .editDivTop60 {
    top: 60px;
  }
}
</style>